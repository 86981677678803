import { useState, useEffect } from "react";
import SearchBar from "../utilities/SearchBar";
import VideoList from "../commons/videos/VideoList";
import VideoDetail from "../commons/videos/VideoDetail";
import useVideos from "../../hooks/useVideos";

import PageWrapper from "../templates/PageWrapper";

const PageVideos = () => {
  const [defaultValue] = useState("buffet");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videos, searchVideos] = useVideos(defaultValue);

  useEffect(() => {
    setSelectedVideo(videos[0]);
  }, [videos]);

  return (
    <PageWrapper title="Search Videos">
      <SearchBar
        onSubmit={searchVideos}
        defaultValue={defaultValue}
        title="Enter the text to search videos on YouTube"
      />
      <div className="ui container">
        <div className="ui grid">
          <div className="ui row">
            <div className="eleven wide column">
              <VideoDetail video={selectedVideo} />
            </div>
            <div className="five wide column">
              <VideoList onVideoSelect={setSelectedVideo} videos={videos} />
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PageVideos;
