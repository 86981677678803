import PageWrapper from "../templates/PageWrapper";

import { useState, useEffect } from "react";

import unsplash from "../../apis/unsplash";
import SearchBar from "../utilities/SearchBar";
import ImageList from "../commons/pictures/ImageList";

const PagePictures = () => {
  const [images, setImages] = useState([]);
  const [defaultValue] = useState("food");

  useEffect(() => {
    onSearchSubmit(defaultValue);
  }, [defaultValue]);

  const onSearchSubmit = async (term: string) => {
    const response = await unsplash.get("/search/photos", {
      params: { query: term, per_page: 30 },
    });

    setImages(response.data.results);
  };

  return (
    <PageWrapper title="Search Pictures">
      <div className="ui container">
        <SearchBar
          onSubmit={onSearchSubmit}
          defaultValue={defaultValue}
          title="Enter the text to search pictures"
        />
        <ImageList images={images} />
        <p>
          <small>*Quota: 50 calls / hour</small>
        </p>
      </div>
    </PageWrapper>
  );
};

export default PagePictures;
