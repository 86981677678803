import { useState, useEffect } from "react";
import faker from "faker";

const useImages = () => {
  const [images, setImages] = useState<{ id: number; url: string }[]>([]);

  useEffect(() => {
    const max = 9;
    const min = 5;
    let num = Math.random() * (max - min) + min;
    let tmp: { id: number; url: string }[] = [];
    for (let i = 0; i < num; i++) {
      tmp.push({
        id: i,
        url: `${faker.image.food()}?random=?random=${Math.round(
          Math.random() * 1000
        )}`,
      });
    }

    setImages(tmp);
  }, []);

  return [images] as const;
};

export default useImages;
