import { useState, useEffect } from "react";
import "./CountdownClock.css";

const today = new Date();
const target = new Date(today.getFullYear(), today.getMonth() + 1, 0);
//const target = new Date("August 31, 2021 23:59:59");

export default function CountdownClock() {
  const getTimeDiff = () => {
    let now = new Date();
    let diff = Math.abs(target.getTime() - now.getTime()) / 1000;
    let res = { d: "00", h: "00", m: "00", s: "00" };
    let d: number, h: number, m: number, s: number;

    // Reach to End
    if (now >= target) {
      return res;
    }

    // Day
    d = Math.floor(diff / 86400);
    diff -= d * 86400;

    // Hour
    h = Math.floor(diff / 3600) % 24;
    diff -= h * 3600;

    // Minutes
    m = Math.floor(diff / 60) % 60;
    diff -= m * 60;

    // Second
    s = Math.round(diff);

    // Debug
    //console.log("Day " + d, ", Hour: " + h + ", Minutes: " + m + ", Seconds: " + s);

    // Get Output
    res.d = pad(d, 2);
    res.h = pad(h, 2);
    res.m = pad(m, 2);
    res.s = pad(s, 2);

    return res;
  };

  const pad = (num: number, size: number) => {
    var s = "000000000" + num;
    return s.substr(s.length - size);
  };

  let t = getTimeDiff();

  const [day, setDay] = useState(t.d);
  const [hour, setHour] = useState(t.h);
  const [minute, setMinute] = useState(t.m);
  const [second, setSecond] = useState(t.s);

  useEffect(() => {
    const mySession = window.setInterval(() => {
      let t = getTimeDiff();
      setDay(t.d);
      setHour(t.h);
      setMinute(t.m);
      setSecond(t.s);
    }, 1000);
    return () => {
      clearInterval(mySession);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-countdown">
      <div className="title">Coming Soon...</div>
      <div className="countdown_digit">
        <div className="time days">
          <span className="digits">{day}</span>
          <span className="desc">Days</span>
        </div>
        <div className="divider">:</div>
        <div className="time hours">
          <span className="digits">{hour}</span>
          <span className="desc">Hours</span>
        </div>
        <div className="divider">:</div>
        <div className="time minutes">
          <span className="digits">{minute}</span>
          <span className="desc">Mins</span>
        </div>
        <div className="divider">:</div>
        <div className="time seconds">
          <span className="digits">{second}</span>
          <span className="desc">Secs</span>
        </div>
      </div>
    </div>
  );
}
