import PageWrapper from "../templates/PageWrapper";
import Thermometer from "../commons/Thermometer";

const PageThermometer = () => {
  return (
    <PageWrapper title="Thermometer">
      <Thermometer current={15000} goal={20000} />
    </PageWrapper>
  );
};

export default PageThermometer;
