import { useState, useEffect } from "react";
import "./Thermometer.css";

type Props = {
  current: number;
  goal: number;
};

const Thermometer = ({ current, goal }: Props) => {
  const [meterWidth, setMeterWidth] = useState({ width: "0%" });
  const [currentNumber, setCurrentNumber] = useState(0);

  useEffect(() => {
    let w = 0;
    if (goal !== 0) {
      w = (current / goal) * 100;
    }
    setMeterWidth({ width: w + "%" });
    setCurrentNumber(0);

    return () => {
      setMeterWidth({ width: 0 + "%" });
      setCurrentNumber(0);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, goal]);

  useEffect(() => {
    // duration, 3.2s, run 64 times
    const interval = Math.floor(current / 64);
    const mySession = window.setInterval(() => {
      let runningNumber = Math.min(currentNumber + interval, current);
      setCurrentNumber(runningNumber);
      if (runningNumber >= current) {
        clearInterval(mySession);
      }
    }, 50);
    return () => {
      clearInterval(mySession);
    };
  }, [current, currentNumber]);

  const getCurrencyFormat = (val: number) => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    return formatter.format(val);
  };

  return (
    <div className="container-thermometer">
      <div className="container">
        <div className="bg"></div>
        <div className={`meter`} style={meterWidth}>
          <div className="current">{getCurrencyFormat(currentNumber)}</div>
        </div>
      </div>
      <div className="goal">{getCurrencyFormat(goal)}</div>
    </div>
  );
};
export default Thermometer;
