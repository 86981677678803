import React from "react";

type Props = {
  href: string;
  target?: "_blank" | "_self" | "_parent" | "_top";
  className?: string;
  children?:
    | React.ReactChild
    | React.ReactChildren
    | React.ReactChild[]
    | React.ReactChildren[];
};

const WebLink = ({
  href,
  target = "_blank",
  className = "",
  children,
}: Props) => {
  return (
    <a href={href} target={target} className={className}>
      {children}
    </a>
  );
};

export default WebLink;
