import React from "react";

interface Props {
  //image: { [key: string]: string | { [key: string]: string } };
  image: { description: string; urls: { regular: string } };
}

interface State {
  spans: number;
}

class ImageCard extends React.Component<Props, State> {
  private imageRef: React.RefObject<HTMLImageElement>;
  constructor(props: any) {
    super(props);

    this.state = { spans: 0 };

    this.imageRef = React.createRef();
  }
  componentDidMount() {
    //console.log(this.imageRef.current.clientHeight);
    if (this.imageRef.current) {
      this.imageRef.current.addEventListener("load", this.setSpans);
    }
  }
  setSpans = () => {
    //console.log(this.imageRef.current.clientHeight);
    if (this.imageRef.current) {
      const height = this.imageRef.current.clientHeight;

      const spans = Math.ceil(height / 10); // + 1;

      // When key and value are the same name, ES15, we can shorten the set like this
      this.setState({ spans });
    }
  };
  render() {
    const { description, urls } = this.props.image;

    return (
      <div style={{ gridRowEnd: `span ${this.state.spans}` }}>
        <img ref={this.imageRef} alt={description} src={urls.regular} />
      </div>
    );
  }
}
export default ImageCard;
