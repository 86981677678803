import "./ImageList.css";
import ImageCard from "./ImageCard";

type Props = {
  images: any[];
};

const ImageList = ({ images }: Props) => {
  const imgs = images.map((image) => {
    return <ImageCard key={image.id} image={image} />;
  });

  return <div className="image-list">{imgs}</div>;
};

export default ImageList;
