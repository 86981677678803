import { useState, useEffect } from "react";
import jsonPlaceholder from "../apis/jsonPlaceholder";

interface IPost {
  userId: number;
  id: number;
  title: string;
  body: string;
}

const usePosts = (num: number = 10) => {
  const [posts, setPosts] = useState<IPost[]>([]);

  useEffect(() => {
    const fetchPosts = async () => {
      const response = await jsonPlaceholder.get("/posts");

      setPosts(response.data.slice(0, num));
    };
    fetchPosts();
  }, [num]);

  return [posts] as const; // React conversion
};

export default usePosts;
