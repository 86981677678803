import { IVideo } from "./Video";
import VideoItem from "./VideoItem";

type Props = {
  videos: IVideo[];
  onVideoSelect: any;
};

const VideoList = ({ videos, onVideoSelect }: Props) => {
  const renderedList = videos.map((video) => {
    return (
      <VideoItem
        key={video.id.videoId}
        onVideoSelect={onVideoSelect}
        video={video}
      />
    );
  });

  return <div className="ui relaxed divided list">{renderedList}</div>;
};

export default VideoList;
