import { JSXElement } from "@babel/types";
import React from "react";

type Props = {
  title: string | JSXElement;
  children?:
    | React.ReactChild
    | React.ReactChildren
    | React.ReactChild[]
    | React.ReactChildren[]
    | Element
    | null;
};

const PageWrapper = ({ title, children }: Props) => {
  return (
    <div className="page-wrapper">
      <div className="ui container">
        <div className="ui basic segment">
          <h1 className="upper">{title}</h1>
          <hr />
          <div className="p-y-lg">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
