import React, { useState } from "react";

import PageWrapper from "../templates/PageWrapper";
import Lightbox from "../commons/Lightbox";

const PageLightbox = () => {
  const [isLightboxOn, setIsLightboxOn] = useState(true);

  const closeLightbox = () => {
    setIsLightboxOn(false);
  };

  const renderActions = () => {
    return (
      <React.Fragment>
        <button onClick={closeLightbox} className="ui button positive">
          Confirm
        </button>
        <button onClick={closeLightbox} className="ui button">
          Cancel
        </button>
      </React.Fragment>
    );
  };

  const renderContent = () => {
    return "Click confirm or cancel to close the lightbox.";
  };

  const renderLightbox = () => {
    if (!isLightboxOn) {
      return null;
    }

    return (
      <Lightbox
        title="Lightbox Title Goes Here"
        content={renderContent()}
        actions={renderActions()}
        onDismiss={closeLightbox}
      />
    );
  };

  return (
    <PageWrapper title="Lightbox">
      <div className="container">
        <button
          onClick={() => setIsLightboxOn(true)}
          className="ui button primary"
        >
          Turn On the Lightbox
        </button>
        {renderLightbox()}
      </div>
    </PageWrapper>
  );
};

export default PageLightbox;
