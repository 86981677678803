import React from "react";
import ReactDOM from "react-dom";

type Props = {
  title?: string;
  content?: string;
  actions?: null | string | JSX.Element;
  onDismiss?: () => void;
};

const Lightbox = ({ title, content, actions, onDismiss }: Props) => {
  return ReactDOM.createPortal(
    <div className="lightbox">
      <div onClick={onDismiss} className="ui dimmer modals visible active">
        <div
          onClick={(e) => e.stopPropagation()}
          className="ui standard modal visible active"
        >
          <i onClick={onDismiss} className="close icon"></i>
          <div className="header">{title}</div>
          <div className="content">{content}</div>
          <div className="actions">{actions}</div>
        </div>
      </div>
    </div>,
    document.querySelector("#lightbox") as HTMLElement
  );
};

Lightbox.defaultProps = {
  title: "",
  content: "",
  actions: null,
};

export default Lightbox;
