import React, { useContext } from "react";
import { Link } from "react-router-dom";
import NavContext from "../../contexts/NavContext";
import WebLink from "../utilities/WebLink";

const SidebarMenu = () => {
  const navs = useContext(NavContext);

  const renderNavs = () => {
    return Object.entries(navs).map((items) => {
      return (
        <div className="item" key={items[0]}>
          <div className="header">{items[0]}</div>
          <div className="menu">{renderNavItems(items[1])}</div>
        </div>
      );
    });
  };

  const renderNavItems = (items: any[]) => {
    return items.map((item) => {
      return (
        <React.Fragment key={item.name}>{renderNavItem(item)}</React.Fragment>
      );
    });
  };

  const renderNavItem = (item: any) => {
    switch (item.type) {
      case "WEB_LINK":
        return (
          <WebLink href={item.url} className="item">
            {item.name} {item.icon ? <i className={`icon ${item.icon}`} /> : ""}
          </WebLink>
        );
      case "PAGE":
      default:
        return (
          <Link to={item.url} className="item">
            {item.name}
          </Link>
        );
    }
  };
  return (
    <div className="ui inverted vertical thin menu visible sidebar overlay">
      {renderNavs()}
    </div>
  );
};
export default SidebarMenu;
