import React from "react";

const Navigation = {
  Home: [
    {
      id: 1,
      name: "Introduction",
      type: "PAGE",
      url: "/",
      icon: null,
    },
  ],
  Projects: [
    {
      id: 101,
      name: "Search Pictures",
      type: "PAGE",
      url: "/pictures",
      icon: null,
    },
    {
      id: 102,
      name: "Search Videos",
      type: "PAGE",
      url: "/videos",
      icon: null,
    },
  ],
  Components: [
    {
      id: 201,
      name: "Countdown Clock",
      type: "PAGE",
      url: "/countdown",
      icon: null,
    },
    {
      id: 202,
      name: "Thermometer",
      type: "PAGE",
      url: "/thermometer",
      icon: null,
    },
    {
      id: 203,
      name: "Gallery",
      type: "PAGE",
      url: "/gallery",
      icon: null,
    },
    {
      id: 204,
      name: "Lightbox",
      type: "PAGE",
      url: "/lightbox",
      icon: null,
    },
  ],
  Elements: [
    {
      id: 301,
      name: "Loading Spinner",
      type: "PAGE",
      url: "/spinner",
      icon: null,
    },
  ],
  About: [
    {
      id: 901,
      name: "LinkedIn",
      type: "WEB_LINK",
      url: "https://www.linkedin.com/in/deswings/",
      icon: "linkedin",
    },
    {
      id: 902,
      name: "Resume",
      type: "WEB_LINK",
      url: "/pdf/Raymond_Resume.pdf",
      icon: "file pdf outline",
    },
  ],
};

export default React.createContext(Navigation);
