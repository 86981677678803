import PageWrapper from "../templates/PageWrapper";

const Home = () => {
  return (
    <PageWrapper title="Raymond Chan">
      <div className="ui grid">
        <div className="eight wide column">
          <div className="ui items">
            <div className="item">
              <div className="image">
                <img src="/images/profile.jpg" alt="portrait" />
              </div>
              <div className="content">
                <p className="intro">
                  I am a Full-stack software engineer with 15+ years of
                  experience to build responsive websites, mobile apps, and
                  interactive features which improve the websites performance
                  and UX. I am skilled in HTML5, CSS3, JavaScript, TypeScript,
                  React.js, PHP, MySQL / MSSQL Database.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Home;
