import "./css/utilities.css";
import "./App.css";

import { Router, Switch, Route } from "react-router-dom"; // Switch only show one of the route

import SidebarMenu from "./components/navigations/SidebarMenu";
import history from "./components/navigations/History";

// Home
import Home from "./components/pages/Home";

// Projects
import PagePictures from "./components/pages/PagePictures";
import PageVideos from "./components/pages/PageVideos";

// Components
import PageCountdown from "./components/pages/PageCountdown";
import PageThermometer from "./components/pages/PageThermometer";
import PageGallery from "./components/pages/PageGallery";
import PageLightbox from "./components/pages/PageLightbox";

// Elements
import PageSpinner from "./components/pages/PageSpinner";

const App = () => {
  return (
    <div className="app">
      <Router history={history}>
        <SidebarMenu />
        <div className="pusher">
          <div id="container-contents" className="container-contents">
            <Switch>
              <Route path="/" exact component={Home} />

              <Route path="/pictures" exact component={PagePictures} />
              <Route path="/videos" exact component={PageVideos} />

              <Route path="/countdown" exact component={PageCountdown} />
              <Route path="/thermometer" exact component={PageThermometer} />
              <Route path="/gallery" exact component={PageGallery} />
              <Route path="/lightbox" exact component={PageLightbox} />

              <Route path="/spinner" exact component={PageSpinner} />
            </Switch>
          </div>
        </div>
      </Router>
    </div>
  );
};

export default App;
