import { useState } from "react";

type Props = {
  onSubmit: (term: string) => void;
  defaultValue?: string;
  title?: string;
};

const SearchBar = ({
  onSubmit,
  defaultValue = "",
  title = "Enter a search term:",
}: Props) => {
  const [term, setTerm] = useState(defaultValue);

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(term);
  };

  return (
    <div className="ui segment">
      <form onSubmit={onFormSubmit} className="ui form">
        <div className="field">
          <label>{title}</label>
          <input
            type="text"
            value={term}
            onChange={(e) => setTerm(e.target.value)}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchBar;
