import PageWrapper from "../templates/PageWrapper";
import Spinner from "../utilities/Spinner";

const Page = () => {
  return (
    <PageWrapper title="Loading Spinner">
      <h3>Loading...</h3>
      <div className="container" style={{ minHeight: 200 }}>
        <Spinner />
      </div>
      <br />
      <br />
      <h3>Searching...</h3>
      <div className="container" style={{ minHeight: 200 }}>
        <Spinner message="Searching..." />
      </div>
    </PageWrapper>
  );
};

export default Page;
