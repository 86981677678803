import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.querySelector("#root")
);

// Autorefresh index.js
// npm install --save-dev @types/webpack-env
if (module.hot) {
  module.hot.accept();
}
