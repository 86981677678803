import "./PageGallery.css";

import Carousel from "react-elastic-carousel";

import PageWrapper from "../templates/PageWrapper";
import useImages from "../../hooks/useImages";
import usePosts from "../../hooks/usePosts";

const PageGallery = () => {
  const [images] = useImages();
  const [posts] = usePosts(10);

  return (
    <PageWrapper title="Gallery">
      <h3>Gallery 1</h3>
      <div className="gallery m-b-xl">
        <div className="carousel-wrapper m-b-xl">
          <Carousel isRTL={false} itemsToShow={1} itemsToScroll={1}>
            {images.map((image) => (
              <div key={image.id}>
                <img src={image.url} alt="gallery item" />
              </div>
            ))}
          </Carousel>
        </div>
        <p>
          <small>
            <em>
              *I am using faker.js to generate some randome images, you may see
              the same images sometimes
            </em>
          </small>
        </p>
      </div>
      <br />
      <br />
      <h3>Gallery 2</h3>
      <div id="gallery-2" className="gallery m-b-xl">
        <div className="carousel-wrapper m-b-xl">
          <Carousel
            isRTL={false}
            itemsToShow={3}
            itemsToScroll={3}
            focusOnSelect={false}
            enableAutoPlay
            autoPlaySpeed={5000}
          >
            {posts.map((post) => (
              <div key={post.title} className="gallery-item">
                <div className="item">
                  <div className="content p-a-md">
                    <h3 className="header m-b-md">{post.title}</h3>
                    <div className="description">
                      <p>{post.body}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PageGallery;

/*
const Items = [
  { id: 21, title: faker.company.companyName(), contents: faker.company.bs() },
  { id: 22, title: faker.company.companyName(), contents: faker.company.bs() },
  { id: 23, title: faker.company.companyName(), contents: faker.company.bs() },
  { id: 24, title: faker.company.companyName(), contents: faker.company.bs() },
  { id: 25, title: faker.company.companyName(), contents: faker.company.bs() },
];
const [items] = useState(Items);
*/
