import PageWrapper from "../templates/PageWrapper";
import CountdownClock from "../commons/CountdownClock";

const PageCountdown = () => {
  return (
    <PageWrapper title="Countdown Clock">
      <CountdownClock />
    </PageWrapper>
  );
};

export default PageCountdown;
